<template>
    <vs-row ref="toolbar" align="center" class="builder-toolbar" :style="{left: (!userMode ? (localOptions.x + options.width + 10) : localOptions.x) + 'px', top: topPos, opacity: ready ? 1 : 0}">
        <!-- show x_mm,y_mm, width_mm,height_mm-->
        <p v-if="!userMode" class="info">X {{ options.x_mm.toFixed(2) }}mm, Y {{ options.y_mm.toFixed(2) }}mm | {{ options.width_mm.toFixed(2) }}mm x {{ options.height_mm.toFixed(2) }}mm</p>
        <!-- color picker-->
        <vs-input style="width: 80px; z-index: 1000;" type="color" v-model="localOptions.fill" v-if="['text', 'qrcode'].includes(localOptions.type)" @input="(e) => $emit('fillChanged', e)"/>
         <!-- size-->
            <vs-input style="width: 80px; z-index: 1000;" type="number" v-model="localOptions.fontSize" min="6" max="48" v-if="['text'].includes(localOptions.type)" @change="(e) => $emit('sizeChanged', e.target.value)"/>

            <vs-select style="width: 150px; z-index: 1000;" v-model="localOptions.fontFamily" v-if="['text'].includes(localOptions.type)">
                <vs-option v-for="font in fonts" :key="font" :value="font" :label="font">{{ font }}</vs-option>
            </vs-select>

         <!-- BOLD, UNDERLINE, ITALIC IF TEXT-->
            <vs-button @click="buildFontStyle('bold')" :transparent="!localOptions.bold" :dark="localOptions.bold" style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-bold bx-sm"></i>
            </vs-button>

            <vs-button @click="buildFontStyle('underline')" :transparent="!localOptions.underline" :dark="localOptions.underline"  style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-underline bx-sm"></i>
            </vs-button>

            <vs-button @click="buildFontStyle('italic')" :transparent="!localOptions.italic" :dark="localOptions.italic"  style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-italic bx-sm"></i>
            </vs-button>
            
            <!-- TEXT ALIGNMENT -->
            <vs-button @click="setTextAlign('left')" :transparent="localOptions.textAlign !== 'left'" :dark="localOptions.textAlign === 'left'" style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-align-left bx-sm"></i>
            </vs-button>
            
            <vs-button @click="setTextAlign('center')" :transparent="localOptions.textAlign !== 'center'" :dark="localOptions.textAlign === 'center'" style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-align-middle bx-sm"></i>
            </vs-button>
            
            <vs-button @click="setTextAlign('right')" :transparent="localOptions.textAlign !== 'right'" :dark="localOptions.textAlign === 'right'" style="height: 42px; width: 42px;" v-if="['text'].includes(localOptions.type)">
                <i class="bx bx-align-right bx-sm"></i>
            </vs-button>

            <!-- upload-->
            <vs-button color="dark" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type)" @click="$emit('upload')">
                <i class="bx bx-upload bx-sm"></i>
            </vs-button>

            <!-- crop -->
            <vs-button color="dark" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type)" @click="$emit('crop')">
                <i class="bx bx-crop bx-sm"></i>
            </vs-button>

            <!-- square or circle -->
            <vs-button color="dark" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type) && !userMode" @click="$emit('shapeChanged', 'square')">
                <i class="bx bx-square bx-sm"></i>
            </vs-button>
            <vs-button color="dark" style="height: 42px; width: 42px;" v-if="['image'].includes(localOptions.type) && !userMode" @click="$emit('shapeChanged', 'circle')">
                <i class="bx bx-circle bx-sm"></i>
            </vs-button>

            <!-- lock -->
            <vs-button dark style="height: 42px; width: 42px;" @click="toggleLock" v-if="localOptions.locked && !userMode">
                <i class="bx bx-lock-alt bx-sm"></i>
            </vs-button>

            <!-- unlock -->
            <vs-button transparent style="height: 42px; width: 42px;" @click="toggleLock" v-if="!localOptions.locked && !userMode">
                <i class="bx bx-lock-open-alt bx-sm"></i>
            </vs-button>
            
            <!-- trash on right -->
            <vs-button border color="danger" style="height: 42px; width: 42px;" v-if="!userMode" @click="$emit('delete')">
                <i class="bx bx-trash bx-sm"></i>
            </vs-button>

            <vs-row v-if="['qrcode'].includes(localOptions.type)">
                <vs-input block v-model="localOptions.text" @input="(e) => $emit('textChanged', e)" />                
            </vs-row>

            <!-- add button to duplicate-->
            <vs-button color="dark" style="height: 42px; width: 42px;" v-if="!userMode" @click="$emit('duplicate')">
                <i class="bx bx-copy bx-sm"></i>
            </vs-button>

    </vs-row>
</template>

<script>
import { apiCall } from '@/client.js';
export default {
    name: 'BuilderToolbar',
    props: {
        options: {
            type: Object,
            default: () => {}
        },

        userMode: {
            type: Boolean,
            default: false
        },

        fonts: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        topPos(){
            let e = this.localOptions.y - this.toolbarHeight;
            if(e < 0){
                e = 0;
            }
            return e + 'px'
        },
    },
    methods: {
        toggleLock(){
            this.localOptions.locked = !this.localOptions.locked;
            this.localOptions.__ob__.dep.notify();
            this.$emit('lockChanged', this.localOptions.locked);
        },


        updateToolbarHeight(){
            this.toolbarHeight = this.$refs.toolbar.$el.clientHeight + 10;
            console.log('toolbarHeight', this.toolbarHeight);
        },

        buildFontStyle(toggle = null){
            if(toggle != null){
                this.localOptions[toggle] = !this.localOptions[toggle];
            }
            // build fontStyle: it's a string with all the options separed by space
            let fontStyle = '';
            if (this.localOptions.bold) {
                fontStyle += 'bold ';
            }
            if (this.localOptions.italic) {
                fontStyle += 'italic ';
            }
            if (this.localOptions.underline) {
                fontStyle += 'underline ';
            }
            if(this.localOptions.type == 'text'){
                this.$emit('fontStyleChanged', fontStyle);
            }

            this.localOptions.__ob__.dep.notify();
        },

        setTextAlign(align) {
            this.localOptions.textAlign = align;
            this.$emit('textAlignChanged', align);
            this.localOptions.__ob__.dep.notify();
        }
    },

    mounted() {

        Object.assign(this.localOptions, this.options);
        console.log(this.localOptions);
        // parse fontstyle
        if(this.localOptions.fontStyle){
            let fontStyle = this.localOptions.fontStyle.split(' ').concat(this.localOptions.textDecoration.split(' '));
            this.localOptions.bold = fontStyle.includes('bold');
            this.localOptions.italic = fontStyle.includes('italic');
            this.localOptions.underline = fontStyle.includes('underline');
        }

        this.localOptions.align = this.options.align || 'left';

        console.log(this.localOptions.align);

        this.localOptions.__ob__.dep.notify();

        setTimeout(() => {
            this.ready = true;
            this.updateToolbarHeight();
        }, 5);
    },

    data(){
        return {

            localOptions: {
                fontFamily: '',
                textAlign: 'left',
            },

            toolbarHeight: 60,
            ready: false
        }
    },

    watch: {
        options: {
            handler: function (val) {
                // only set x and y
                this.localOptions.x = val.x;
                this.localOptions.y = val.y;

                // set type
                this.localOptions.type = val.type;

                this.localOptions.__ob__.dep.notify();

                this.updateToolbarHeight();
            },
            deep: true
        },

        'localOptions.fontFamily': {
            handler: function (val) {
                console.log(val + ' changed');
                this.$emit('fontFamilyChanged', val);
            }
        }
    }
}
</script>

<style scoped>
.builder-toolbar {
    position: absolute;
    width: auto;
    gap: 5px!important;
    z-index: 10000;
    background-color:rgba(255,255,255,0.4);
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    /* blur its background glass effect */
    backdrop-filter: blur(10px);
    min-width: 400px;
}

.builder-toolbar .info {
    position: absolute;
    background-color:rgba(255,255,255,0.8);
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    /* blur its background glass effect */
    backdrop-filter: blur(10px);
    bottom: -50px;
    font-size: 15px!important;
}
</style>