<template>
    <vs-row direction="column">
      <vs-dialog v-model="builderImageDialog" prevent-close>
        <template #header>
          <h3>Scegli da predefiniti</h3>
        </template>

        <vs-row>
          <img :src="builderImage?.imageUrl" style="max-height: 200px;" v-for="builderImage in builderImages" @click="selectBuilderImage(builderImage)" :key="builderImage.id" />
        </vs-row>
      </vs-dialog>

        <vs-dialog v-model="gradientModal" prevent-close>
            <template #header>
                <h3>Seleziona i colori del gradiente</h3>
            </template>
            <vs-row style="gap: 30px; margin-top: 10px;" direction="column">
                    <vs-input v-model="gradientA" block label="Colore A" type="color" />
                    <vs-input v-model="gradientB" block label="Colore B" type="color" />
            </vs-row>
            <template #footer>
                <vs-button @click="addGradient(gradientA, gradientB)" size="large" block>Conferma</vs-button>
            </template>
        </vs-dialog>
        <vs-dialog v-model="cropperDialog" prevent-close>
          <cropper v-if="cropperDialog"
          class="cropper"
          ref="cropper"
          :src="cropperProps.src"
          :stencil-props="{
            aspectRatio: cropperProps.aspectRatio,

          }"
          @change="cropperProps.change"
          default-boundaries="fill"
          :stencil-component="cropperProps.isCircle ? CircleStencil : undefined"
        >
      </cropper>

        <!-- button to rotate left, right flip horizzontal and vertical, also to fill-->
         <vs-row style="position: absolute; left: 20px; top: 20px; display: none;" direction="column">
          <vs-button  icon @click="$refs.cropper.rotate(-90)"> <i class="bx bx-rotate-left bx-sm"></i> </vs-button>
          <vs-button  icon @click="$refs.cropper.rotate(90)"> <i class="bx bx-rotate-right bx-sm"></i> </vs-button>

         </vs-row>

        <template #footer>
          <vs-button @click="cropperDialog = false" size="large" block>Conferma e chiudi</vs-button>
        </template>
        </vs-dialog>

        <input type="file" ref="fileInput" @change="fileInputChange" style="display: none;" />
        <vs-row justify="space-between" align="center" v-if="!builderData.userMode">
          <p>Debug:
                {{ layout.width }}x{{ layout.height }} mm -
                {{ layout.marginTop }}x{{ layout.marginRight }}x{{ layout.marginBottom }}x{{ layout.marginLeft }} mm -
                {{ configKonva.width.toFixed(2) }}x{{ configKonva.height.toFixed(2) }} px (scaled by {{ scaleFactor.toFixed(2) }})
          </p>
          <vs-row style="width: unset!important;">
            <vs-button border dark icon @click="userMode = !userMode"> <i class="bx bx-pen bx-sm"></i> {{ userMode ? 'Passa a Amministratore' : 'Passa a Utente' }}</vs-button>
            <!-- add paragraph, add image buttons with icon as well -->
            <vs-button icon @click="addParagraph"> <i class="bx bx-text bx-sm"></i> Paragrafo</vs-button>
            <vs-button icon @click="builderImageDialog = true"> <i class="bx bx-palette bx-sm"></i> Predefiniti</vs-button>
            <vs-button icon @click="uploadFile(null, 'builderImage', 'image/*')"> <i class="bx bx-image bx-sm"></i> Immagine</vs-button> 
            <vs-button icon @click="addQR()"> <i class="bx bx-barcode bx-sm"></i> QR Code</vs-button>
            <vs-button icon @click="uploadFile(null, 'builderBackgroundImage', 'image/*')"> <i class="bx bx-image-add bx-sm"></i> Sfondo</vs-button>
            <vs-button icon @click="gradientModal = true"> <i class="bx bx-brush bx-sm"></i> Gradiente</vs-button>
        
            <vs-button @click="editLayout" icon> <i class="bx bx-save bx-sm"></i> Salva</vs-button>
            </vs-row>
        </vs-row>
        <vs-row v-if="configKonva.width > 0" ref="stageContainer" :justify="userMode ? 'center' : undefined">
            <BuilderToolbar id="builder-toolbar" v-if="toolbarOpts" :options="toolbarOpts" :user-mode="userMode" @delete="deleteElement" @upload="replaceImage" @crop="initCropper(toolbarOpts.id)" 
              @fillChanged="fillChanged" @sizeChanged="sizeChanged" @fontStyleChanged="fontStyleChanged" @lockChanged="lockChanged" @shapeChanged="shapeChanged" :fonts="loadedFonts" @fontFamilyChanged="fontFamilyChanged" @textChanged="textChanged" @duplicate="duplicateElement" @textAlignChanged="textAlignChanged"/>
              
            <v-stage :config="configKonva" @mousedown="handleStageMouseDown" @touchstart="handleStageMouseDown" ref="stage">
               <v-layer>
                <v-rect :config="backgroundRect"></v-rect>
               </v-layer>

                

                <v-layer>
                    <v-image v-for="image in elements.filter(e => e.type === 'background')" :config="image" :key="image.name" @transformend="handleTransformEnd" />
                    
                    <!--image-->
                    <v-image v-for="image in elements.filter(e => e.type === 'image' || e.type == 'qrcode')" :config="image" :key="image.name" @transformend="handleTransformEnd"  @transform="handleTransform" @dragmove="handleDrag" />
                    <!--text-->
                    <v-text v-for="paragraph in elements.filter(e => e.type === 'text')" :config="paragraph" :key="paragraph.name" @transformend="handleTransformEnd" @dblclick="doubleClick" @dbltap="doubleClick" @transform="handleTransform" @dragmove="handleDrag" />

                    <v-transformer ref="transformer" />


                </v-layer>

                <!-- Add guidelines layer -->
                <v-layer ref="guidelinesLayer">
                    <v-line
                        v-for="(line, i) in guidelines"
                        :key="'guideline-'+i"
                        :config="{
                            points: line.points,
                            stroke: '#000000',
                            strokeWidth: 4,
                            dash: [4, 1],
                            listening: false
                        }"
                    />
                </v-layer>

                <v-layer>
                    

                    <!-- draw the crocino di taglio on the corneres, it looks like a cross -->

                    <!-- top left corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginTop > 0 && this.layout.marginLeft > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX - (5 * MM_TO_PX),

                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // go to left
                                this.layout.marginLeft * MM_TO_PX - (5 * MM_TO_PX),
                                this.layout.marginTop * MM_TO_PX,

                                
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- top right corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginRight > 0 && this.layout.marginTop > 0 && !this.savingPreview"

                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX - (5 * MM_TO_PX),

                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,

                                // go to right
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX + (5 * MM_TO_PX),
                                this.layout.marginTop * MM_TO_PX,
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- bottom right corner -->
                    <v-line
                    v-if="builderData.layout.type != 'Spine' && this.layout.marginBottom > 0 && this.layout.marginRight > 0 && !this.savingPreview"

                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX + (5 * MM_TO_PX),
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // go to right
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX + (5 * MM_TO_PX),
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>

                    <!-- bottom left corner -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginBottom > 0 && this.layout.marginLeft > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX - (5 * MM_TO_PX),
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,

                                // go to left
                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX + (5 * MM_TO_PX),
                            ],
                            stroke: 'red',
                            strokeWidth: 0.3 * MM_TO_PX, // 1px
                        }"
                    ></v-line>
                    
                </v-layer>
                <v-layer>
                    <!-- Margin lines - each line is drawn only if its margin value > 0 -->
                    
                    <!-- Top margin line -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginTop > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX
                            ],
                            stroke: 'black',
                            strokeWidth: 0.2 * MM_TO_PX,
                            dash: [DASH_LENGTH * MM_TO_PX, DASH_GAP * MM_TO_PX],
                        }"
                    ></v-line>
                    
                    <!-- Right margin line -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginRight > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX,
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX
                            ],
                            stroke: 'black',
                            strokeWidth: 0.2 * MM_TO_PX,
                            dash: [DASH_LENGTH * MM_TO_PX, DASH_GAP * MM_TO_PX],
                        }"
                    ></v-line>
                    
                    <!-- Bottom margin line -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginBottom > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                (this.layout.width - this.layout.marginRight) * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,
                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX
                            ],
                            stroke: 'black',
                            strokeWidth: 0.2 * MM_TO_PX,
                            dash: [DASH_LENGTH * MM_TO_PX, DASH_GAP * MM_TO_PX],
                        }"
                    ></v-line>
                    
                    <!-- Left margin line -->
                    <v-line
                        v-if="builderData.layout.type != 'Spine' && this.layout.marginLeft > 0 && !this.savingPreview"
                        :config="{
                            points: [
                                this.layout.marginLeft * MM_TO_PX,
                                (this.layout.height - this.layout.marginBottom) * MM_TO_PX,
                                this.layout.marginLeft * MM_TO_PX,
                                this.layout.marginTop * MM_TO_PX
                            ],
                            stroke: 'black',
                            strokeWidth: 0.2 * MM_TO_PX,
                            dash: [DASH_LENGTH * MM_TO_PX, DASH_GAP * MM_TO_PX],
                        }"
                    ></v-line>
                </v-layer>
            </v-stage>
        </vs-row>
    </vs-row>
</template>

<script>
import WebFont from 'webfontloader'
import { Cropper, CircleStencil} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import {
    apiCall,
} from '@/client';
import {
    s3Client
} from '@/s3Client';
import BuilderToolbar from '@/components/BuilderToolbar.vue';
const QRCode = require('qrcode')

const DPI = 400;

// calculate how many pixels needed per mm
const MM_TO_PX = DPI / 25.4;
const PX_TO_MM = 1 / MM_TO_PX;

const DASH_LENGTH = 5;
const DASH_GAP = 5;

export default {
    props: {
      builderData: {
        type: Object,
        default: () => {},
        required: true,
      }
    },
    data() {
        return {
            layout: {
                name: 'A4',
                width: 210, // in mm
                height: 297, // in mm
                marginTop: 5,
                marginRight: 5,
                marginBottom: 5,
                marginLeft: 5,
            },
            configKonva: {
                width: 0,
                height: 0,
                scaleX: 1,
                scaleY: 1,
            },
            
            scaleFactor: 1,

            elements: [],

            MM_TO_PX,
            PX_TO_MM,
            DASH_LENGTH,
            DASH_GAP,

            selectedShapeName: '',

            toolbarOpts: null,

            userMode: false,

            cropperProps: null,
            cropperDialog: false,
            cropperConfirm: null,
            CircleStencil,

            gradientModal: false,
            gradientA: '#ffffff',
            gradientB: '#000000',
            

            googleFonts: [
            "Roboto",
            "Open Sans",
            "Lato",
            "Montserrat",
            "Source Sans Pro",
            "Poppins",
            "Oswald",
            "Raleway",
            "Merriweather",
            "Nunito",
            "Ubuntu",
            "Playfair Display",
            "Rubik",
            "Work Sans",
            "Noto Sans",
            "Inter",
            "PT Sans",
            "Fira Sans",
            "Quicksand",
            "Titillium Web",
            "DM Sans",
            "Cairo",
            "Anton",
            "Bebas Neue",
            "Hind",
            "Barlow",
            "Mulish",
            "IBM Plex Sans",
            "Josefin Sans",
            "Kanit",
            "Comfortaa",
            "Signika",
            "Abel",
            "Exo 2",
            "Muli",
            "Archivo",
            "Zilla Slab",
            "Assistant",
            "Tajawal",
            "Dosis",
            "Varela Round",
            "Manrope",
            "PT Serif",
            "Overpass",
            "Fjalla One",
            "Arimo",
            "Quattrocento",
            "Asap",
            "Cabin",
            "Bitter",
            "Chakra Petch",
            "Spectral",
            "Hind Madurai",
            "Space Grotesk",
            "Lora",
            "Frank Ruhl Libre",
            "Crimson Text",
            "Cormorant Garamond",
            "Jost",
            "Alegreya",
            "IBM Plex Serif",
            "Yanone Kaffeesatz",
            "Public Sans",
            "Domine",
            "Teko",
            "Nanum Gothic",
            "Karla",
            "Noto Serif",
            "Gothic A1",
            "Maven Pro",
            "Saira",
            "Chivo",
            "Urbanist",
            "Sora",
            "Bai Jamjuree",
            "Arvo",
            "Hind Siliguri",
            "Heebo",
            "Slabo 27px",
            "Rokkitt",
            "El Messiri",
            "Pathway Gothic One",
            "Scope One",
            "Prata",
            "Noticia Text",
            "Play",
            "Aleo",
            "Great Vibes",
            "Courgette",
            "Dancing Script",
            "Pacifico",
            "Caveat",
            "Shadows Into Light",
            "Amatic SC",
            "Indie Flower",
            "Patrick Hand",
            "Satisfy",
            "Yellowtail",
            "Architects Daughter",
            "Sacramento",
            "Gloria Hallelujah",
            "Handlee",
            "Just Another Hand",
            "Reenie Beanie"
          ],

          loadedFonts: [],
          builderImages: [],
          builderImage: null,
          builderImageDialog: false,

          // Add new properties for guidelines
          guidelines: [],
          guidelineThreshold: 5, // snap threshold in pixels
          showGuides: false,

          savingPreview: false,
        }
    },

    components: {
        BuilderToolbar,
        Cropper,
    },
    computed: {
        backgroundRect() {
            return {
                x: 0,
                y: 0,
                width: this.layout.width * MM_TO_PX,
                height: this.layout.height * MM_TO_PX,
                fill: "white",
                listening: false
            }
        }
    },
watch: {
    selectedShapeName(n){
        if(!n){
            this.toolbarOpts = null;
        }
    },

    cropperProps(n){
        if(n){
            this.cropperDialog = true;
        } else {
            this.cropperDialog = false;
        }
    },

    cropperDialog(n){
        if(!n){
            this.cropperProps = null;
        }
    },

    userMode(n){
      // toggle draggable except for background
      this.elements.forEach(e => {
        if(e.type !== 'background'){
          e.draggable = !n;
        }
      });

    }
},

    methods: {
      async textChanged(text){
        // update qr code text
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        // generate new qrcode and replace it
        const image = await this.generateQRCode(text, node.qrCodeFill);
        node.image(image);

        const element = this.elements.find(e => e.name === this.selectedShapeName);
        element.text = text;
      },

      async getBuilderImages() {
        var result = await apiCall('GET', '/BuilderImages');
        if (result.status == 200) {
            let images = await Promise.all(result.data.map(async (image) => {
                const signedUrl = await s3Client.getSignedDownloadURL({name: image.id});
                image.imageUrl = signedUrl.url;
                return image;
            }));
            this.builderImages = images;
        }
      },

      async selectBuilderImage(image){
        const i = new Image();
        i.src = image.imageUrl;
        i.id = image.id;
        
        await new Promise((resolve, reject) => {
          i.onload = () => {
            const u = uuidv4();
            this.addImageCore(i, {
              id: u,
              name: u,
            });
            resolve();
          }
        });

        this.builderImageDialog = false;
      },

      fontFamilyChanged(font){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          node.fontFamily(font);
          this.updateTransformer();
        }
      },

      shapeChanged(shape){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        // it must be an image
        if(!node){
          return;
        }

        if(node.attrs.type !== 'image'){
          return;
        }

        // set corner radius to 10000
        if(shape == 'circle'){
          node.cornerRadius(10000);
        } else {
          node.cornerRadius(0);
        }
      },

      addGradient(colorA, colorB){
        // remove gradient if exists
        this.elements = this.elements.filter(e => e.type !== 'background');

        this.elements.push({
          x: 0,
          y: 0,
          width: this.layout.width * MM_TO_PX,
          height: this.layout.height * MM_TO_PX,
          fillLinearGradientStartPoint: { x: 0, y: 0 },
          fillLinearGradientEndPoint: { x: this.layout.width * MM_TO_PX, y: this.layout.height * MM_TO_PX },
          fillLinearGradientColorStops: [0, colorA, 1, colorB],
          draggable: !this.userMode,
          listening: !this.userMode,
          type: 'background',
          name: uuidv4(),
        });
        this.gradientModal = false;
      },

      replaceImage(){
        if(!this.userMode){
          this.uploadFile(this.toolbarOpts.id, 'builderImage', 'image/*', 'cropper')
        } else {
          this.$emit('selectImage', this.toolbarOpts.id);
        }
      },

      lockChanged(locked){
        // update locked property in elements
        const idx = this.elements.findIndex(e => e.name === this.selectedShapeName);
        if(idx >= 0){
          this.elements[idx].locked = locked;
        }
      },

      async fillChanged(color){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          const type = this.elements.find(e => e.name === this.selectedShapeName).type;
          if(type == 'text'){
            node.fill(color);
            this.updateTransformer();
          } else if(type == 'qrcode'){
            // generate new qrcode and replace it
            const image = await this.generateQRCode(node.text, color);
            node.image(image);
            // update qrcodefill
            node.qrCodeFill = color;
          }
        }
      },

      sizeChanged(size){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          node.fontSize(size * MM_TO_PX);
          this.updateTransformer();
        }
      },

      fontStyleChanged(style){
        const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if(node){
          // if includes underline
          if(style.includes('underline')){
            node.textDecoration('underline');
            style = style.replace('underline', '');
          } else {
            node.textDecoration('');
          }
          node.fontStyle(style);
          this.updateTransformer();
        }
      },

      initCropper(id){
        const node = this.$refs.stage.getNode().findOne('.' + id);
        this.cropperProps = {
          src: node.image().src,
          aspectRatio: node.width() / node.height(),
          isCircle: node.cornerRadius() == 10000,
          change: (evt) => {
            node.crop({
              x: evt.coordinates.left,
              y: evt.coordinates.top,
              width: evt.coordinates.width,
              height: evt.coordinates.height,
            });
          }
        }
      },

      deleteElement(){
        // delete selected element
        const idx = this.elements.findIndex(e => e.name === this.selectedShapeName);
        if(idx >= 0){
            this.elements.splice(idx, 1);
            this.selectedShapeName = '';

            const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
            if(node){
              node.destroy();
            }
            this.updateTransformer();
        }
      },

      uploadFile(id, scope, types, postProcess = null) {
            // if id is null uuidv4
            if (!id) {
                id = uuidv4();
            }

            // set attributes
            this.$refs.fileInput.setAttribute('data-file-name', id);
            this.$refs.fileInput.setAttribute('data-file-scope', scope);
            this.$refs.fileInput.setAttribute('accept', types);
            if(postProcess){
                this.$refs.fileInput.setAttribute('data-post-process', postProcess);
            }
            this.$refs.fileInput.click();
        },

        async fileInputChange(e) {
            var file = e.target && e.target.files ? e.target.files[0] : null;
            let fileName, fileScope, postProcess = null;

            if(!file){
                e.isMock = true;
                file = e;
                fileName = e.fileName;
                fileScope = e.fileScope;
                postProcess = e.postProcess;
            } else {
              // get from data attrs
              fileName = e.target.getAttribute('data-file-name');
              fileScope = e.target.getAttribute('data-file-scope');
              postProcess = e.target.getAttribute('data-post-process');
            }

            

            //console.log('Handling file', fileName, fileScope, postProcess);

            if (file) {
                if(file.size > 1024 * 1024 * 10){
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `La foto non può essere più grande di 10MB.`
                    })
                    return;
                }
                const loading = this.$vs.loading({
                    text: 'Caricamento in corso...'
                })
                try {
                    let result = null;
                    if(!e.isMock){
                      result = await s3Client.uploadFile(file, fileName);
                    } else {
                      result = {
                        status: 200,
                      }
                    }
                    if(result.status == 200){
                        // get signed download url
                        const signedUrl = await s3Client.getSignedDownloadURL({name: fileName}); // signedUrl.url;
                        if(fileScope == 'builderImage'){

                          const image = new Image();
                          image.crossOrigin = 'anonymous';
                          image.src = signedUrl.url;
                          image.id = fileName;

                          let layerId = e.isMock ? e.layerId : fileName;
                          await new Promise((resolve, reject) => {
                            image.onload = () => {
                              let e = this.$refs.stage.getNode().findOne('.' + layerId);
                              if(e){
                                  console.log('Found element', e);
                                  const oldImage = e.image();

                                  e.image(image);

                                  //console.log('Builder Image ' + oldImage.id + ' replaced with ' + image.id);

                                  // it must fill the same space
                                  // understand which is the biggest dimension and then crop it to fill the space

                                  const newH = image.naturalHeight;
                                  const newW = image.naturalWidth;
                                  const oldH = oldImage.naturalHeight;
                                  const oldW = oldImage.naturalWidth;

                                  // it must FILL not FIT the space
                                  const scale = Math.min(newH / oldH, newW / oldW);

                                  
                                  e.crop({
                                      x: 0,
                                      y: 0,
                                      width: oldW * scale,
                                      height: oldH * scale,
                                  });

                                  if(postProcess == 'cropper'){
                                    this.cropperProps = {
                                      src: signedUrl.url,
                                      aspectRatio: e.width() / e.height(),
                                      change: (evt) => {
                                        //console.log(evt);
                                        e.crop({
                                          x: evt.coordinates.left,
                                          y: evt.coordinates.top,
                                          width: evt.coordinates.width,
                                          height: evt.coordinates.height,
                                        });

                                        /*// evt.image.transforms.rotate
                                        if(evt.image.transforms.rotate){
                                          e.crop({
                                            x: evt.coordinates.left,
                                            y: evt.coordinates.top,
                                            width: evt.coordinates.height,
                                            height: evt.coordinates.width,
                                          });
                                        }*/
                                        
                                      }
                                    }
                                  }


                              } else {
                                console.log('Element not found for id ' + layerId + ' adding new image');
                                this.addImageCore(image, {
                                  id: fileName,
                                  name: fileName,
                                });
                              }

                              resolve();
                            }
                          });
                          
                        } else if(fileScope == 'builderBackgroundImage'){
                          const image = new Image();
                          image.crossOrigin = 'anonymous';
                          image.src = signedUrl.url;
                          image.id = fileName;

                          await new Promise((resolve, reject) => {
                            image.onload = () => {
                                // Convert layout from mm to pixels
                                const containerPxWidth = this.layout.width * MM_TO_PX;
                                const containerPxHeight = this.layout.height * MM_TO_PX;

                                // Calculate scale so that the image covers the entire container
                                const scale = Math.max(
                                    containerPxWidth / image.naturalWidth,
                                    containerPxHeight / image.naturalHeight
                                );
                                
                                const newWidth = image.naturalWidth * scale;
                                const newHeight = image.naturalHeight * scale;
                                
                                // Center the image by calculating offsets
                                const offsetX = (containerPxWidth - newWidth) / 2;
                                const offsetY = (containerPxHeight - newHeight) / 2;
                                
                                this.addImageCore(image, {
                                    x: offsetX,
                                    y: offsetY,
                                    width: newWidth,
                                    height: newHeight,
                                    draggable: !this.userMode,
                                    listening: !this.userMode,
                                    type: "background",
                                });

                                resolve();
                            }
                          });
                        }
                    } else {
                        throw new Error('Error uploading file');
                    }
                } catch (error) {
                    console.error(error);
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare la foto.`
                    })
                }

                loading.close();
            }

            if(e.target){
                // reset input
                e.target.value = '';
            }
      },
      getSerializedData(){
        
        return JSON.stringify(this.elements.map(e => {
            // get node position in mm from konva, bacuse the elements are not updated
            // with the new position

            const konvaNode = this.$refs.stage.getNode().findOne('.' + e.name);
            const pos = konvaNode.getAbsolutePosition();
            e.x = pos.x/this.scaleFactor;
            e.y = pos.y/this.scaleFactor;


            // handle image sources
            if(e.type === 'qrcode'){
                const image = konvaNode.image();
                return {
                    ...e,
                    image: image ? image.src : undefined,
                    qrCodeFill: konvaNode.qrCodeFill,
                }
            } else if(e.type === 'image' || e.type === 'background'){
              const image = konvaNode.image();
              const i = {
                  ...e,
                  crop: konvaNode.crop(),
                  cornerRadius: konvaNode.cornerRadius(),
                  image: image ? image.id : undefined,
              }
              //console.log('Serialized image', i);
              return i;
            } else if(e.type == 'text'){
              // add style, size, font, decoration
              return {
                  ...e,
                  text: konvaNode.text(),
                  fontSize: konvaNode.fontSize(),
                  fontFamily: konvaNode.fontFamily(),
                  fill: konvaNode.fill(),
                  align: konvaNode.align(),
                  textDecoration: konvaNode.textDecoration(),
                  fontStyle: konvaNode.fontStyle(),
              }
            } else {
                return e;
            }
        }));
      },

      getStageDistanceFromContainer(){
        // get konvajs bounding rect and container bounding rect
        const konva = document.querySelector('.konvajs-content').getBoundingClientRect();
        const container = this.$refs.stageContainer.$el.getBoundingClientRect();

        return {
            x: konva.x - container.x,
            y: konva.y - container.y,
        }
      },

      async savePreviewPicture(pictureId = null){
        // remove focus from transformer
        this.$refs.transformer.getNode().nodes([]);

        this.savingPreview = true;

        // render konva image to blob
        const stage = this.$refs.stage.getNode();
        const image = stage.toDataURL({
            pixelRatio: 1,
            mimeType: 'image/jpeg',
            quality: 1,

            x: this.layout.marginLeft * MM_TO_PX * this.scaleFactor,
            y: this.layout.marginTop * MM_TO_PX * this.scaleFactor,

            width: (
              (stage.width() * this.scaleFactor)
              - 
              (this.layout.marginRight * MM_TO_PX * this.scaleFactor)
              -
              (this.layout.marginLeft * MM_TO_PX * this.scaleFactor)
            ),
            height: (
              (stage.height() * this.scaleFactor)
              - 
              (this.layout.marginBottom * MM_TO_PX * this.scaleFactor)
              -
              (this.layout.marginTop * MM_TO_PX * this.scaleFactor)
            ),

        });

        this.savingPreview = false;

        //console.log('image', image);


        if(!pictureId){
          pictureId = this.builderData.layout.previewPictureId;
        }

        // image is a b64 convert it to blob
        const blob = await fetch(image).then(res => res.blob());


        // upload blob to s3 with filename from previewPictureId if not null
        if(pictureId){
            const result = await s3Client.uploadBlob(blob, pictureId);
            if(result.status != 200){
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare l'anteprima.`
                })
            }
        } else {
            this.$vs.notification({
                icon: '<i class="bx bx-error"></i>',
                color: 'danger',
                position: 'top-right',
                title: 'Ops!',
                text: `Impossibile salvare l'anteprima.`
            })
        }
        
      },

      async exportHQPng(){
        // remove focus from transformer
        this.$refs.transformer.getNode().nodes([]);
        // save scales
        const scaleX = this.$refs.stage.getNode().scaleX();
        const scaleY = this.$refs.stage.getNode().scaleY();

        // reset scales
        this.$refs.stage.getNode().scaleX(1);
        this.$refs.stage.getNode().scaleY(1);

        // render konva image to blob
        const stage = this.$refs.stage.getNode();
        const image = stage.toDataURL({
            pixelRatio: 2,
            mimeType: 'image/png',
            quality: 1,
        });


        // set scales back
        this.$refs.stage.getNode().scaleX(scaleX);
        this.$refs.stage.getNode().scaleY(scaleY);

        const blob = await fetch(image).then(res => res.blob());

        // trigger download
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'render.png';
        a.click();

        // revoke url
        URL.revokeObjectURL(url);

        
      },

      async editLayout() {
            const loading = this.$vs.loading();
            var result = await apiCall('PATCH', '/Product/Layout', {
                ...this.builderData.layout,
                jsonData: this.getSerializedData()
            });
            await this.savePreviewPicture();
            loading.close();
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async loadSave(){
          if(this.builderData.layout.type == 'Spine'){
            this.layout.height = this.builderData.product.height;
            this.layout.width = this.builderData.productVariant.dorsino;
            this.layout.marginTop = 0;
            this.layout.marginRight = 0;
            this.layout.marginBottom = 0;
            this.layout.marginLeft = 0;
          } else {
            this.layout.width = this.builderData.product.width;
            this.layout.height = this.builderData.product.height;
            this.layout.marginTop = this.builderData.product.marginTop;
            this.layout.marginRight = this.builderData.product.marginRight;
            this.layout.marginBottom = this.builderData.product.marginBottom;
            this.layout.marginLeft = this.builderData.product.marginLeft;
          }

          let data = JSON.parse(this.builderData.layout.jsonData);
          if(!data) return;

          // edit draggable
          data = data.map(e => {
            e.draggable = !this.userMode;
            if(e.type == 'background'){
              e.listening = !this.userMode;
            }

            if(e.locked){
              e.draggable = !this.userMode; // if locked, draggable is false for non admin
              e.listening = !this.userMode;
            }
            return e;
          });

          this.elements = await Promise.all(data.map(async e => {
            
              if(e.type === 'qrcode'){
                  const image = new Image();
                  image.crossOrigin = 'anonymous';
                  image.src = e.image;
                  await new Promise((resolve, reject) => {
                      image.onload = () => {
                          resolve();
                      }

                      image.onerror = (err) => {
                          reject(err);
                      }
                  });
                  return {
                      ...e,
                      image,
                  }
              } else if(e.type === 'image' || e.type === 'background'){
                  // url from s3
                  if(e.image){
                    const downloadUrl = await s3Client.getSignedDownloadURL({name: e.image});
                    const image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.src = downloadUrl.url;
                    image.id = e.image;
                    await new Promise((resolve, reject) => {
                        image.onload = () => {
                            resolve();
                        }

                        image.onerror = (err) => {
                            reject(err);
                        }
                    });

                    return {
                      ...e,
                        image,
                    }
                  }

                  
              }
              return e;
          }));

        },

        async init() {
          this.userMode = this.builderData.userMode || false;

            this.loadSave().then(() => {
              console.log('Builder: Ready');
              this.$emit('ready');
            });

           // get screen page available space
            let availWidth = window.screen.availWidth;
            let availHeight = window.screen.availHeight - (this.builderData.heightSubtract || 200); // 200 px are lost in admin

            if(this.builderData.ssr){
              //headless mode
              availWidth = 1920;
              availHeight = 1080;
            }
            
            this.configKonva.width = this.layout.width * MM_TO_PX;
            this.configKonva.height = this.layout.height * MM_TO_PX;
            this.scaleFactor = Math.min(
                availWidth / this.configKonva.width,
                availHeight / this.configKonva.height
            );



            this.configKonva.scaleX = this.scaleFactor;
            this.configKonva.scaleY = this.scaleFactor;

            this.$nextTick(() => {
                // if user mode, resize stage
              let el = document.querySelector('.konvajs-content');
              console.log('el',el);
              if(el){
                  el.style.width = this.layout.width * MM_TO_PX * this.scaleFactor + 'px';
                  el.style.height = this.layout.height * MM_TO_PX * this.scaleFactor + 'px';
                  el.style.overflow = 'hidden';
              }
              
            });
        },

        handleTransform(e) {
        const element = e.target;
        
        // Apply snapping for width and height - find elements with similar dimensions
        if (!e.evt.shiftKey && element.attrs.type !== 'text') {
            const stage = this.$refs.stage.getNode();
            const threshold = this.guidelineThreshold;
            
            this.elements.forEach(elementConfig => {
                if (elementConfig.name === this.selectedShapeName || elementConfig.type === 'background') return;
                const node = stage.findOne('.' + elementConfig.name);
                if (!node) return;
                
                // Snap width if close
                if (Math.abs(element.width() * element.scaleX() - node.width()) < threshold) {
                    element.scaleX(node.width() / element.width());
                }
                
                // Snap height if close
                if (Math.abs(element.height() * element.scaleY() - node.height()) < threshold) {
                    element.scaleY(node.height() / element.height());
                }
            });
        }

        if(element.attrs.type === 'text'){
            element.setAttrs({
                width: Math.max(5, element.width() * element.scaleX()),
                height: Math.max(5, element.height() * element.scaleY()),
                scaleX: 1,
                scaleY: 1,
            });
        } else {
            element.setAttrs({
                width: Math.max(5, element.width() * element.scaleX()),
                height: Math.max(5, element.height() * element.scaleY()),
                scaleX: 1,
                scaleY: 1,
            });
        }

        const boundingRect = this.getStageDistanceFromContainer();

        // move toolbar
        this.toolbarOpts = {
            ...this.toolbarOpts,
            // also sum how far is stage from left of parent stageContainer
            x: element.x() * this.scaleFactor + boundingRect.x,
            y: element.y() * this.scaleFactor + boundingRect.y,

            // pass also size in px
            width: element.width() * this.scaleFactor,
            height: element.height() * this.scaleFactor,
            
            // add millimeter values
            x_mm: element.x() * PX_TO_MM,
            y_mm: element.y() * PX_TO_MM,
            width_mm: element.width() * PX_TO_MM,
            height_mm: element.height() * PX_TO_MM
        };
    },

    handleDrag(e) {
        if(this.userMode) return;

        const element = e.target;

        // Find snap points and apply snapping
        if (!e.evt.shiftKey) { // Hold shift to temporarily disable snapping
            const guides = this.findSnapPoints();
            
            // Apply snapping for x-axis
            if (guides.verticalGuides && guides.verticalGuides.length > 0) {
                const closestGuide = guides.verticalGuides[0];
                
                // Apply snap
                if (closestGuide.snap === 'left') {
                    element.x(closestGuide.guide);
                } else if (closestGuide.snap === 'center') {
                    element.x(closestGuide.guide - element.width() / 2);
                } else if (closestGuide.snap === 'right') {
                    element.x(closestGuide.guide - element.width());
                }
            }
            
            // Apply snapping for y-axis
            if (guides.horizontalGuides && guides.horizontalGuides.length > 0) {
                const closestGuide = guides.horizontalGuides[0];
                
                // Apply snap
                if (closestGuide.snap === 'top') {
                    element.y(closestGuide.guide);
                } else if (closestGuide.snap === 'middle') {
                    element.y(closestGuide.guide - element.height() / 2);
                } else if (closestGuide.snap === 'bottom') {
                    element.y(closestGuide.guide - element.height());
                }
            }
            
            // Show guidelines
            this.showGuides = true;
            this.showGuidelines(guides);
        } else {
            // Clear guidelines when shift is pressed
            this.guidelines = [];
        }

        const boundingRect = this.getStageDistanceFromContainer();

        // move toolbar
        this.toolbarOpts = {
            ...this.toolbarOpts,
            x: element.x() * this.scaleFactor + boundingRect.x,
            y: element.y() * this.scaleFactor + boundingRect.y,
            
            // add millimeter values
            x_mm: element.x() * PX_TO_MM,
            y_mm: element.y() * PX_TO_MM
        };

    },

    addImageCore(image, opts = {}) {
        // get w and h from naturalWidth and naturalHeight, but it must fits in the layout
        let w = 100;
        // calc h to keep the aspect ratio
        const h = (w * image.naturalHeight) / image.naturalWidth;

        const u = uuidv4();
        let o = {
            type: "image",

            id: u,
            name: u,
            x: (this.layout.width - w) * MM_TO_PX / 2,
            y: (this.layout.height - h) * MM_TO_PX / 2,
            image,
            draggable: this.userMode ? false : true,

            width: w * MM_TO_PX,
            height: h * MM_TO_PX,

            zIndex: 0,

            locked: false,
        };

        o = Object.assign(o, opts); // override default options
        
        this.elements.push(o);

    },

    
    async generateQRCode(text = 'wised.it', color = '#000000FF') {            
        return await new Promise((resolve, reject) => {
            QRCode.toDataURL(text, {
                rendererOpts: {
                    quality: 1
                },
                margin: 1,
                type: 'image/png',

                color: {
                dark: color,
                light:"#0000"
                }
            },(err, url) => {
                if (err) {
                    console.error(err)
                    reject(err);
                }
                const image = new Image();
                image.crossOrigin = 'anonymous';
                image.src = url;
                image.onload = () => {
                    resolve(image);
                }
            })
        });
    },

    async addQR(text = 'wised.it', color = '#000000FF') {    
      const image = await this.generateQRCode(text, color);        
            
      this.addImageCore(image, {
          type: "qrcode",
          width: 50 * MM_TO_PX,
          height: 50 * MM_TO_PX,
          text,
          qrCodeFill: color,
      });
    },

    addParagraph(){
        if (!this.paragraphOptions) {
            this.$set(this, 'paragraphOptions', []);
        }

        this.elements.push({
            type: "text",

            id: "paragraph_" + (this.elements.length + 1),
            name: "paragraph_" + (this.elements.length + 1),
            x: 1 * MM_TO_PX,
            y: 1 * MM_TO_PX,
            text: "Nuovo paragrafo",
            fontSize: 20 * MM_TO_PX,
            fontFamily: this.loadedFonts[0],
            fill: "black",
            draggable: this.userMode ? false : true,

            width: Math.min(...[100, this.layout.width]) * MM_TO_PX,
            height: 100 * MM_TO_PX,

            editingMode: false,

            locked: false,
        });

        // add konva transformer


    },


  doubleClick(e) {
    const textNode = e.target;
    const tr = this.$refs.transformer.getNode();
    const stage = tr.getStage();
    // hide text node and transformer:
    textNode.hide();
    tr.hide();

    // create textarea over canvas with absolute position
    // first we need to find position for textarea
    // how to find it?

    // at first lets find position of text node relative to the stage:
    var textPosition = textNode.absolutePosition();
    var textSize = {
      width: textNode.width() * this.scaleFactor,
      height: textNode.height() * this.scaleFactor,
    };

    // so position of textarea will be the sum of positions above:
    var areaPosition = {
      x: stage.container().offsetLeft + (textPosition.x),
      y: stage.container().offsetTop + (textPosition.y )
    };

    // create textarea and style it
    var textarea = document.createElement('textarea');
    this.$refs.stageContainer.$el.appendChild(textarea);
    var rotation = textNode.rotation();

    // apply many styles to match text on canvas as close as possible
    // remember that text rendering on canvas and on the textarea can be different
    // and sometimes it is hard to make it 100% the same. But we will try...
    textarea.value = textNode.text();
    textarea.style.position = 'absolute';
    textarea.style.top = (areaPosition.y ) + 'px';
    textarea.style.left = (areaPosition.x ) + 'px';
    textarea.style.width = textSize.width + 'px';
    textarea.style.height = textSize.height + 'px';
    textarea.style.fontSize = textNode.fontSize() * this.scaleFactor + 'px';
    textarea.style.border = '2px solid #ddd';
    textarea.style.padding = '0px';
    textarea.style.margin = '0px';
    textarea.style.overflow = 'hidden';
    textarea.style.background = 'none';
    textarea.style.outline = 'none';
    textarea.style.resize = 'none';
    textarea.style.lineHeight = textNode.lineHeight();
    textarea.style.fontFamily = textNode.fontFamily();
    textarea.style.transformOrigin = 'left top';
    textarea.style.textAlign = textNode.align();
    textarea.style.color = textNode.fill();

    // set decoration and style
    if(textNode.textDecoration()){
      textarea.style.textDecoration = textNode.textDecoration();
    }

    if(textNode.fontStyle()){
      textarea.style.fontStyle = textNode.fontStyle();
    }

    // unset minWidth, maxWidth, minHeight, maxHeight
    textarea.style.minWidth = '0';
    textarea.style.maxWidth = 'none';
    textarea.style.minHeight = textSize.height + 'px';
    textarea.style.maxHeight = 'none';
    var transform = '';
    if (rotation) {
      transform += 'rotateZ(' + rotation + 'deg)';
    }

    var px = 0;
    // also we need to slightly move textarea on firefox
    // because it jumps a bit
    var isFirefox =
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (isFirefox) {
      px += 2 + Math.round(textNode.fontSize() / 20);
    }
    transform += 'translateY(-' + px + 'px)';

    textarea.style.transform = transform;

    // reset height
    textarea.style.height = 'auto';
    // after browsers resized it we can set actual value
    textarea.style.height = textarea.scrollHeight + 3 + 'px';

    textarea.focus();

    function removeTextarea() {
      textarea.parentNode.removeChild(textarea);
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('selectionchange', handleSelectionChange);
      textNode.show();
      tr.show();
      tr.forceUpdate();
    }

    function setTextareaWidth(newWidth) {
      if (!newWidth) {
        // set width for placeholder
        newWidth = textNode.placeholder.length * textNode.fontSize();
      }
      // some extra fixes on different browsers
      var isSafari =
        navigator.userAgent.toLowerCase().indexOf('safari') > -1;
      var isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isSafari || isFirefox) {
        newWidth = Math.ceil(newWidth);
      }

      var isEdge =
        document.documentMode || /Edge/.test(navigator.userAgent);
      if (isEdge) {
        newWidth += 1;
      }
      textarea.style.width = newWidth + 'px';
    }

    textarea.addEventListener('keydown', function (e) {
      // hide on enter
      // but don't hide on shift + enter
      if (e.keyCode === 13 && !e.shiftKey) {
        textNode.text(textarea.value);
        removeTextarea();
      }
      // on esc do not set value back to node
      if (e.keyCode === 27) {
        removeTextarea();
      }
    });

    textarea.addEventListener('keydown', function (e) {
      scale = textNode.getAbsoluteScale().x;
      setTextareaWidth(textNode.width() * scale);
      textarea.style.height = 'auto';
      textarea.style.height =
        textarea.scrollHeight + textNode.fontSize() + 'px';
    });

    // Track if mouse was pressed inside textarea and if we're in a selection operation
    let mouseDownOnTextarea = false;
    let selectionInProgress = false;
    
    function handleMouseDown(e) {
      mouseDownOnTextarea = e.target === textarea;
      if (mouseDownOnTextarea) {
        selectionInProgress = true;
      }
    }
    
    function handleMouseUp(e) {
      // End the selection operation but maintain the mouseDownOnTextarea state
      // for the upcoming click event
      if (selectionInProgress) {
        selectionInProgress = false;
        // Prevent the subsequent click event from closing the textarea
        // by delaying the reset of mouseDownOnTextarea
        setTimeout(() => {
          mouseDownOnTextarea = false;
        }, 10);
        return;
      }
    }
    
    function handleOutsideClick(e) {
      // Only close if this is a genuine click outside (not part of selection)
      if (e.target !== textarea && !mouseDownOnTextarea && !selectionInProgress) {
        textNode.text(textarea.value);
        removeTextarea();
      }
    }
    
    // Add a document-level selection event handler to better detect text selection
    function handleSelectionChange() {
      const selection = window.getSelection();
      if (selection.type === 'Range' && mouseDownOnTextarea) {
        // We have an active selection that started in our textarea
        selectionInProgress = true;
      }
    }
    
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('selectionchange', handleSelectionChange);
    
    setTimeout(() => {
      window.addEventListener('click', handleOutsideClick);
    });
  },

handleTransformEnd(e) {
      if(this.userMode) return;
      
      // Clear guidelines
      this.guidelines = [];
      
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const rect = this.elements.find(
        (r) => r.name === e.target.name()
      );
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();

      // as scale stretches the width and height, we need to recalculate them
        rect.width = e.target.width()
        rect.height = e.target.height()


    },
    handleStageMouseDown(e) {
      
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      const name = e.target.name();
      const rect = this.elements.find((r) => r.name === name);
      if (rect) {
        const node = e.target;
        const element = e.target;
        this.toolbarOpts = null;
        this.selectedShapeName = name;

        this.$nextTick(() => {
          const boundingRect = this.getStageDistanceFromContainer();
          this.toolbarOpts = {
              ...this.toolbarOpts || {},
            x: element.x() * this.scaleFactor + boundingRect.x,
            y: element.y() * this.scaleFactor + boundingRect.y,
            // pass also size in px
            width: element.width() * this.scaleFactor,
            height: element.height() * this.scaleFactor,
            
            // add millimeter values
            x_mm: element.x() * PX_TO_MM,
            y_mm: element.y() * PX_TO_MM,
            width_mm: element.width() * PX_TO_MM,
            height_mm: element.height() * PX_TO_MM,
            
            type: rect.type,
            id: rect.id,
            locked: rect.locked,

            // add options for paragraph
            ... (rect.type === 'text' ? {
              text: node.text(),
              fontSize: node.fontSize() / MM_TO_PX,
              fontFamily: node.fontFamily(),
              fill: node.fill(),
              textAlign: node.align(),
              textDecoration: node.textDecoration(),
              fontStyle: node.fontStyle(),
            } : {}),

            ... (rect.type === 'qrcode' ? {
              text: rect.text,
              fill: rect.qrCodeFill,
            } : {}),
            
          };

        });
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      const selectedNode = stage.findOne('.' + selectedShapeName);
      if (this.userMode){
        // disable stretching
        this.$refs.transformer.getNode().enabledAnchors([]);
        // disable rotation using rotateEnabled
        this.$refs.transformer.getNode().rotateEnabled(false);
      } else {
        // enable only preserving aspect ratio, disable others
        // if is text,allow stretching without preserving aspect ratio
        if(selectedNode && selectedNode.attrs.type === 'text'){
          this.$refs.transformer.getNode().enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right', 'middle-left', 'middle-right', 'top-center', 'bottom-center']);
        } else {
          this.$refs.transformer.getNode().enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);
        }
        
        // enable rotation
        this.$refs.transformer.getNode().rotateEnabled(true);
      }
      

      
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },
    duplicateElement() {
      // Find the element to duplicate
      const originalElement = this.elements.find(e => e.name === this.selectedShapeName);
      if (!originalElement) return;
    
      // Create a deep copy of the element
      const newElement = JSON.parse(JSON.stringify(originalElement));
      
      // Generate new unique IDs
      const newId = uuidv4();
      newElement.id = newId;
      newElement.name = newId;
      
      // Offset the position slightly to make it clear it's a copy
      newElement.x += 10 * MM_TO_PX;
      newElement.y += 10 * MM_TO_PX;
    
      // For elements with special handling
      if (newElement.type === 'qrcode' || newElement.type === 'image' || newElement.type === 'background') {
        // Get the current node's image to create a copy
        const originalNode = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
        if (originalNode) {
          const originalImage = originalNode.image();
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.src = originalImage.src;
          image.id = originalImage.id;
          newElement.image = image;
          
          // Copy specific properties for QR code
          if (newElement.type === 'qrcode') {
            newElement.qrCodeFill = originalNode.qrCodeFill;
          }
        }
      }
    
      // Add the duplicate to the elements array
      this.elements.push(newElement);
      
      // Select the new element
      this.selectedShapeName = newElement.name;
      this.$nextTick(() => {
        this.updateTransformer();
      });
    },

    // Add a new method to find snap positions
    findSnapPoints() {
        // Clear previous guidelines
        this.guidelines = [];
        
        // Get the moving node
        const stage = this.$refs.stage.getNode();
        const movingNode = stage.findOne('.' + this.selectedShapeName);
        if (!movingNode) return;
        
        // Get position and dimensions
        const movingNodeBox = {
            x: movingNode.x(),
            y: movingNode.y(),
            width: movingNode.width(),
            height: movingNode.height(),
            centerX: movingNode.x() + movingNode.width() / 2,
            centerY: movingNode.y() + movingNode.height() / 2,
            right: movingNode.x() + movingNode.width(),
            bottom: movingNode.y() + movingNode.height(),
        };
        
        const threshold = this.guidelineThreshold;
        let verticalGuides = [];
        let horizontalGuides = [];
        
        // Check against all other elements
        this.elements.forEach(elementConfig => {
            if (elementConfig.name === this.selectedShapeName || elementConfig.type === 'background') return;
            
            // Find the node
            const node = stage.findOne('.' + elementConfig.name);
            if (!node) return;
            
            // Get position and dimensions
            const nodeBox = {
                x: node.x(),
                y: node.y(),
                width: node.width(),
                height: node.height(),
                centerX: node.x() + node.width() / 2,
                centerY: node.y() + node.height() / 2,
                right: node.x() + node.width(),
                bottom: node.y() + node.height(),
            };
            
            // Check vertical alignment (left, center, right)
            if (Math.abs(movingNodeBox.x - nodeBox.x) < threshold) {
                verticalGuides.push({
                    guide: nodeBox.x,
                    offset: movingNodeBox.x - nodeBox.x,
                    snap: 'left',
                });
            }
            
            if (Math.abs(movingNodeBox.centerX - nodeBox.centerX) < threshold) {
                verticalGuides.push({
                    guide: nodeBox.centerX,
                    offset: movingNodeBox.centerX - nodeBox.centerX,
                    snap: 'center',
                });
            }
            
            if (Math.abs(movingNodeBox.right - nodeBox.right) < threshold) {
                verticalGuides.push({
                    guide: nodeBox.right,
                    offset: movingNodeBox.right - nodeBox.right,
                    snap: 'right',
                });
            }
            
            // Check horizontal alignment (top, middle, bottom)
            if (Math.abs(movingNodeBox.y - nodeBox.y) < threshold) {
                horizontalGuides.push({
                    guide: nodeBox.y,
                    offset: movingNodeBox.y - nodeBox.y,
                    snap: 'top',
                });
            }
            
            if (Math.abs(movingNodeBox.centerY - nodeBox.centerY) < threshold) {
                horizontalGuides.push({
                    guide: nodeBox.centerY,
                    offset: movingNodeBox.centerY - nodeBox.centerY,
                    snap: 'middle',
                });
            }
            
            if (Math.abs(movingNodeBox.bottom - nodeBox.bottom) < threshold) {
                horizontalGuides.push({
                    guide: nodeBox.bottom,
                    offset: movingNodeBox.bottom - nodeBox.bottom,
                    snap: 'bottom',
                });
            }
        });
        
        // Also check against stage boundaries and margins
        // (Add code for margin snapping if needed)
        
        return { verticalGuides, horizontalGuides };
    },
    
    // Add a method to show guidelines
    showGuidelines(guides) {
        this.guidelines = [];
        
        const stage = this.$refs.stage.getNode();
        const stageWidth = stage.width();
        const stageHeight = stage.height();
        
        // Create vertical guidelines
        if (guides.verticalGuides && guides.verticalGuides.length > 0) {
            guides.verticalGuides.forEach(guide => {
                this.guidelines.push({
                    points: [
                        guide.guide, 0,
                        guide.guide, stageHeight
                    ],
                });
            });
        }
        
        // Create horizontal guidelines
        if (guides.horizontalGuides && guides.horizontalGuides.length > 0) {
            guides.horizontalGuides.forEach(guide => {
                this.guidelines.push({
                    points: [
                        0, guide.guide,
                        stageWidth, guide.guide
                    ],
                });
            });
        }
    },

    textAlignChanged(align) {
      const node = this.$refs.stage.getNode().findOne('.' + this.selectedShapeName);
      if (node && node.attrs.type === 'text') {
        node.align(align);
        this.updateTransformer();
        
        // Update the element in the elements array
        const element = this.elements.find(e => e.name === this.selectedShapeName);
        if (element) {
          element.align = align;
        }
      }
    },
  },

  mounted() {
        // Load common Google fonts
        WebFont.load({
          google: {
            families: this.googleFonts,
          },
          fontactive: (e) => {
            this.loadedFonts.push(e);
          },
        });
        this.init();
        this.getBuilderImages();
    }
}
</script>
<style>
.cropper {
  max-height: 80vh!important;
}
</style>